<template>
  <b-overlay :show="loading">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Información general de la cuenta
        </h4>
      </div>
      <div class="card-body">
        <div class="row">

          <div class="col-12">
            <div class="form-group">
              <label>Cliente</label>
              <div class="input-group">
                <v-select
                  v-model="model.customer_id"
                  label="text"
                  :options="customers"
                  :clearable="false"
                  :reduce="value=>value.getAttr('id')"
                  :get-option-label="option=>`${option.getAttr('first_name')} ${option.getAttr('last_name')}`"
                  style="flex:1;"
                />
                <div class="input-group-append">
                  <router-link to="/customers/create">
                    <button class="btn btn-primary">
                      <feather-icon
                        icon="PlusIcon"
                      />
                    </button>
                  </router-link>

                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Tipo de cuenta</label>
              <v-select
                v-model="accountType"
                label="text"
                :options="accountTypes"
                :clearable="false"
                :get-option-label="option=>option.getAttr('name')"
                @input="onAccountTypeChange"
              />
            </div>
          </div>
          <div
            v-if="!model.id && accountType && accountType.getAttr('type', false).toString()==='1'"
            class="col-12 col-md-6"
          >
            <div class="form-group">
              <label>Saldo inicial</label>
              <input
                v-model="model.initial_balance"
                type="number"
                class="form-control"
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="accountType && accountType.getAttr('account_type_fields', false).length>0"
      class="card"
    >
      <div class="card-header">
        <h4 class="card-title">
          Información adicional de la cuenta
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div
            v-for="(field, i) in accountType.getAttr('account_type_fields', false)"
            :key="field.getAttr('id')"
            class="col-12 col-md-6"
          >
            <div class="form-group">
              <label>{{ field.getAttr('name') }}</label>
              <input
                v-model="model.account_field_values[i].value"
                :type="fieldTypes[field.getAttr('type', false)]"
                class="form-control"
                @change="onFieldValueChange($event, i, field.getAttr('type', false))"
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="accountType && accountType.getAttr('type', false).toString()==='2'"
      class="card"
    >
      <div class="card-header">
        <h4 class="card-title">
          Información del préstamo
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div
            class="col-12 col-md-6"
          >
            <div class="form-group">
              <label>Monto de préstamo</label>
              <input
                type="number"
                class="form-control"
              >
            </div>
          </div>

          <div
            class="col-12 col-md-6"
          >
            <div class="form-group">
              <label>Interés ordinario</label>
              <input
                type="number"
                class="form-control"
              >
            </div>
          </div>

          <div
            class="col-12 col-md-6"
          >
            <div class="form-group">
              <label>Plazo (en meses)</label>
              <input
                type="number"
                class="form-control"
              >
            </div>
          </div>

          <div
            class="col-12 col-md-6"
          >
            <div class="form-group">
              <label>Interés moratorio</label>
              <input
                type="number"
                class="form-control"
              >
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="btn-group float-right">
          <router-link to="/accounts">
            <button class="btn btn-danger">
              Cancelar
            </button>
          </router-link>
          <button
            class="btn btn-primary"
            @click="save"
          >
            Guardar
          </button>
        </div>

      </div>
    </div>
  </b-overlay>
</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import Customer from '@/dc-it/models/Customer'
import AccountType from '@/dc-it/models/AccountType'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GeneralService from '@/dc-it/services/GeneralService'
import Account from '@/dc-it/models/Account'

export default {
  name: 'AccountFormView',
  components: { BOverlay, vSelect },
  methods: {
    save() {
      if (this.model.account_type_id === null) {
        this.showError('Debe seleccionar un tipo de cuenta')
        return
      }
      if (this.model.customer_id === null) {
        this.showError('Debe seleccionar un cliente')
        return
      }
      for (let i = 0; i < this.model.account_field_values.length; i += 1) {
        const field = this.accountType.getAttr('account_type_fields', false)[i]
        if (field.getAttr('required') === 'Si' && this.model.account_field_values[i].value === null) {
          this.showError(`Debe ingresar un valor para el campo ${this.accountType.getAttr('account_type_fields', false)[i].getAttr('name')}`)
          return
        }
      }

      this.loading = true
      GeneralService.getInstance().save('accounts', this.model)
        .then(response => {
          if (response.result === 'success') {
            this.$router.replace('/accounts')
          } else {
            this.showError(response.error)
          }
          this.loading = false
        })
        .catch(err => {
          this.showError(err)
        })
    },
    onAccountTypeChange() {
      this.model.account_type_id = this.accountType.getAttr('id')
      this.model.account_field_values = this.accountType.getAttr('account_type_fields', false).map(field => ({
        account_type_field_id: field.getAttr('id'),
        value: null,
      }))
    },
    onFieldValueChange(e, index, type) {
      if (this.fieldTypes[type] === 'file') {
        const input = e.target
        if (!(input.files && input.files)) {
          return
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const file of input.files) {
          const reader = new FileReader()
          const { name } = file

          reader.onload = el => {
            // eslint-disable-next-line new-cap
            this.model.account_field_values[index].filename = name
            this.model.account_field_values[index].file_url = el.target.result
          }
          if ((/(.pdf|.jpg|.jpeg|.png|.gif)$/i).exec(file.name)) {
            reader.readAsDataURL(file)
          } else {
            // eslint-disable-next-line no-alert
            alert(`El archivo ${file.name} no se pudo agregar, ya que, no es un archivo válido`)
          }
        }
      }
    },
    showError(err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'XIcon',
          text: err,
          variant: 'warning',
        },
      })
    },
  },
  setup(props, context) {
    const loading = ref(true)
    const customers = ref([])
    const accountTypes = ref([])
    const accountType = ref(null)
    const accountFieldValueEmpty = ref({
      account_type_field_id: null,
      value: null,
    })
    const model = ref({
      id: null,
      account_type_id: null,
      customer_id: null,
      initial_balance: 0,
      account_field_values: [],
    })

    const fieldTypes = {
      1: 'text',
      2: 'number',
      3: 'file',
      4: 'date',
    }
    const route = context.root.$route

    const initData = async () => {
      loading.value = true
      const accountTypeParam = route.params.accountType
      const promises = [
        customers.value = await Customer.get(),
        accountTypes.value = await AccountType.where('type', '=', accountTypeParam).get(),
      ]
      let accounts = []
      if (route.params.id) {
        promises.push(accounts = await Account.where('id', '=', route.params.id).with(['account_field_values']).get())
      }

      await Promise.all(promises)

      if (accounts.length === 1) {
        accountType.value = accountTypes.value.find(at => at.getAttr('id').toString() === accounts[0].getAttr('account_type_id').toString())

        model.value = accounts[0].toJSON()
        model.value.account_type_id = model.value.account_type
        model.value.customer_id = model.value.customer
        model.value.account_field_values = accounts[0].getAttr('account_field_values')
      }

      loading.value = false
    }

    initData()

    return {
      loading,
      customers,
      accountTypes,
      model,
      accountType,
      accountFieldValueEmpty,
      fieldTypes,
    }
  },
}
</script>

<style scoped>

</style>
